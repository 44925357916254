/*
  this is the "head" entry point for webpack
  files imported here are compiled into head.js or head.css
  see webpack.config.js for configuration
*/

// VENDOR STUFF
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import Cookies from 'js-cookie';
window.Cookies = Cookies;
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

if (context && context.environment_name && context.environment_name != 'development') {
  Sentry.onLoad(function() {
    Sentry.init({
      environment: context.environment_name
    });
  });
}

// OUR STUFF
window.idx = {
  "userAuthenticated": false,
  "userId": null,
  "userLoaded": false
};
